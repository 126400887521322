import firebase from "firebase";

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyCr8ErWVKdPYxu4wggaicKtH1i-VckNEkk",
    authDomain: "ciiberbase.firebaseapp.com",
    projectId: "ciiberbase",
    storageBucket: "ciiberbase.appspot.com",
    messagingSenderId: "709265654434",
    appId: "1:709265654434:web:4d3a860b42dbc2ce504dfb",
    measurementId: "G-SFLWHDZKS3"
};

// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);