<template>
    <section id="hero" class="hero is-medium">
        <div class="hero-head">
            
            <b-navbar>
        <template slot="brand">
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <img
                src="https://www.kingswingsets.com/sites/default/files/logo_0.png"
                >
                <p>{{$appName}}</p>
            </b-navbar-item>
        </template>
        <!-- <template slot="end">
            <b-navbar-item href="#">
                Home
            </b-navbar-item>
            <b-navbar-item href="#">
                Documentation
            </b-navbar-item>
            <b-navbar-item href="#">
                    About
                </b-navbar-item>
                <b-navbar-item href="#">
                    Contact
                </b-navbar-item>
            
        </template> -->

        <template slot="end">
            
            <b-dropdown position="is-bottom-left" append-to-body aria-role="menu" trap-focus>
                    <a
                        class="navbar-item"
                        slot="trigger"
                        role="button"
                        >
                        <span>Login</span>
                        <b-icon icon="menu-down"></b-icon>
                    </a>
                <b-dropdown-item style="width:300px;">
                    <div style="width:300px;"
                        aria-role="menu-item"
                        focusable
                        custom
                        paddingless
                        
                        class="modal-card"
                        >
                        
                            
                                <form action=""> 
                                <section class="modal-card-body">
                                   
                                    <b-field label="Email">
                                        <b-input
                                            type="email"
                                            placeholder="Your email"
                                            required>
                                        </b-input>
                                    </b-field>

                                    <b-field label="Password">
                                        <b-input
                                            type="password"
                                            password-reveal
                                            placeholder="Your password"
                                            required>
                                        </b-input>
                                    </b-field>

                                    <b-checkbox>Remember me</b-checkbox>
                                </section>
                                <footer class="modal-card-foot">
                                    <button class="button is-primary">Login</button>
                                </footer>
                            </form>
                        
                        
                    </div>
                    </b-dropdown-item>
                </b-dropdown>
            
            
            
            
           <!-- <b-navbar-dropdown label="Account">
            <b-navbar-item tag="div">
                <div class="buttons">
                    <a class="button is-light is-fullwidth">
                        Log In
                    </a>
                    <a class="button is-primary is-fullwidth">
                        <strong>Sign up</strong>
                    </a>
                </div>
            </b-navbar-item>
            </b-navbar-dropdown> -->
        </template>
    </b-navbar>
            
            <!-- old---<b-navbar>
                <template slot="brand">
                    <b-navbar-item tag="router-link" :to="{ path: '/' }">
                        <h3 class="logo has-text-green is-size-4">{{$appName}}</h3>
                    </b-navbar-item>
                </template>
                <template slot="end">
                    <b-navbar-item tag="div">
                        <div class="buttons">
                            <a class="a-menu is-size-7 navbar-item">
                                home
                            </a>
                            <a class="a-menu is-size-7 navbar-item">
                                about
                            </a>
                            <a class="a-menu is-size-7 navbar-item">
                                services
                            </a>
                            <a class="a-menu is-size-7 navbar-item">
                                contact
                            </a>
                        </div>

                    </b-navbar-item>
                </template>
            </b-navbar> -->
        </div>
        <!-- <div class="hero-body">
            <div class="container">
                <h1 class="has-text-green is-size-1 is-size-3-mobile">
                    Gym Service
                </h1>
                <p class="has-text-green">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut 
                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                </p>
                <button class="btn has-text-white has-company-bg is-size-7 a-menu">
                    Get started
                </button>
            </div>
        </div>  -->
    </section>
</template>

<style>
@media(min-width: 1024px) {
    .hero-body {
        background-image: url('../../assets/img/gym.svg');
        background-size: 30%;
        background-position: 80% 50%;
        background-repeat: no-repeat;
    }
    .hero-body p {
        width: 600px;
    }
}
</style>

<script>
export default {
    name: 'Navbar',
}
</script>