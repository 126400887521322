<template>
  <div> 
    <Navbar/>
  
  <div class="section is-half" >
  <div class="columns" >
  <div class="column is-one-third" style="background-color:light">
      <b-input placeholder="John Doe" v-model="name"></b-input>
      <b-input placeholder="Date" v-model="date"></b-input>
      <br><b-button slot="reference" type="is-primary" @submit.prevent="onSubmit" @click="createOrder(name, date)">Add New Order</b-button
      >
</div>
<div class="column is-one-third is-pulled-right has-background-light">
<draggable group="1">
<b-table v-if="checkedRows !== undefined && checkedRows.length != 0" 
:data="checkedRows"
:model="transferredRows"
:columns="columns"
>
<template slot="bottom-left">
      <b>Total selected </b>{{ transferredRows.length }}
</template> 
</b-table>
</draggable>
</div>
      </div>
     </div>
  <div class="section is-medium">
  <div class="columns">
  <div class="column is-half">
  
<template>  
    <b-table hoverable mobile-cards
    :data="ordersData.filter((data) => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
     :columns="columns" 
     :checked-rows.sync="checkedRows"
     checkable
     :checkbox-position="checkboxPosition"
     
    ref="maintable"
    @click="checkThis"
    >
    
    <template slot="bottom-left">
      <b>Total selected</b>: {{ checkedRows.length }}
      <!-- <br>
      <b>Data</b>:{{checkedRows}} -->
    </template> 
    <!-- use in table el :columns="columns" -->
      <!-- <b-table-column label="Date" field="date"></b-table-column>
      <b-table-column label="Name" slot="reference" field="name"> </b-table-column>
      <b-table-column label="Delete" centered >
                <button @click="deleteOrder(scope.row.id)" class="button field is-danger"><b-icon icon="delete"></b-icon></button>
     </b-table-column> -->
    
      
      
      <!-- old markup <b-table-column align="right"> -->
       <!-- <template slot="header" :slot-scope="scope">
          <b-input v-model="search" size="mini" placeholder="Type to search" />
        </template> -->
        <!-- <template slot-scope="scope"> -->
          <!-- <b-tooltip
            label="Edit Order"
            > -->
            <!-- <b-input
              placeholder="John Doe"
              v-model="scope.row.name"
              @blur="updateOrder(scope.row.id, scope.row.name, date)"
            ></b-input>
            <b-button size="mini" slot="reference">Edit</b-button>
          </b-tooltip> 
          <b-button
            size="mini"
            type="danger"
            
            >Delete</b-button
          >
        </template> --> 
      <!-- </b-table-column>  -->  
    
    </b-table>
</template>
</div>

<div class="column">

<draggable group="1" >
<div 
v-for="data in checkedRows" 
:key="data.id"
     >
 

 {{data}}
 </div>



</draggable>

    </div>
  
  </div>
  </div> 
    <!-- <Testimonials/>
    <Features/>
    <Newsletter/> -->
    <!-- <Footer year="2020"/> -->
  
<b-icon pack="feather" icon="trash-2-icon"></b-icon>

  <template>
  <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
</template>
  </div>  
  </template>

<script>
import firebase from "./firebaseConfig";
const db = firebase.firestore();
import Navbar from './components/navbar/navbar'
// import Testimonials from './components/testimonials/testimonials'
// import Features from './components/features/features'
// import Newsletter from './components/newsletter/newsletter'
// import Footer from './components/footer/footer'
import draggable from 'vuedraggable'
//const maintable = document.getElementById('maintable')

export default {
  name: "kingswingsets",
  components: {
    draggable,
    Navbar,
    //Trash2Icon,
    // Testimonials,
    // Features,
    // Newsletter,
    // Footer,
    
  },
  data() {
    return {
      
      name: "",
      date: new Date().toISOString().slice(0, 10),
      ordersData: [],
      
      checkedRows: [],
      transferredRows:[],
      search: "",
      checkboxPosition: "left",
      columns: [
        {
          field:"date", 
          label:"Date"
        },
        {
          field:"name", 
          label:"Name",
        
        },
        {
          field:"delete",
          label:"Delete",
          centered:true 
        },
      ]
    };
    
  },
  
  methods: {
    
   //checked from github
  // checkSelectedRow(row, index, event) {
  //           if (!this.isRowCheckable(row)) return
  //           const lastIndex = this.lastCheckedRowIndex
  //           this.lastCheckedRowIndex = index
  //           if (event.shiftKey && lastIndex !== null && index !== lastIndex) {
  //               this.shiftCheckRow(row, index, lastIndex)
  //           } else if (!this.isRowChecked(row)) {
  //               this.newCheckedRows.push(row)
  //           } else {
  //               this.removeCheckedRow(row)
  //           }
  //           this.$emit('check', this.newCheckedRows, row)
  //           // Emit checked rows to update user variable
  //           this.$emit('update:checkedRows', this.newCheckedRows)
  //       },
   // end check from github 
 
  
  checkThis(row){ 
  
  const index = this.checkedRows.indexOf(row);
  if (index > -1) {
  this.checkedRows.splice(index, 1);
      } else {
        this.checkedRows.push(row);
      }
 //row = this.checkedRows.push(row)
 
 //this.$refs.maintable.$emit('check', [row])

 this.$refs.maintable.$emit('update:checkedRows', ...row)//update:checkedRows
    
    
    },

    createOrder(name, date) {
      if (name != "") {
        db.collection("orders")
          .add({ date: date, name: name })
          .then(() => {
            console.log("Document successfully written!");
            this.readOrders();
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
        this.name = "";
      }
    },
    readOrders() {
      this.ordersData = [];
      db.collection("orders")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.ordersData.push({
              id: doc.id,
              name: doc.data().name,
              date: doc.data().date,
            });
            //console.log(doc.id, " => ", doc.data());
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    updateOrder(id, name, date) {
      db.collection("orders")
        .doc(id)
        .update({
          name: name,
          date: date,
        })
        .then(() => {
          console.log("Document successfully updated!");
          this.readOrders();
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
    deleteOrder(id) {
      db.collection("orders")
        .doc(id)
        .delete()
        .then(() => {
          console.log("Document successfully deleted!");
          this.readOrders();
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
  },
  
  mounted() {
    this.readOrders();

  },
};

</script>

<style>

</style>
