import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import { Trash2Icon } from 'vue-feather-icons'
//event-bus --experimental
export const EventBus = new Vue();

//feather icons --experimental
Vue.component('vue-feather-icons', Trash2Icon);
//default `Buefy`. added options experimental
Vue.use(Buefy,{
  defaultIconComponent:'vue-feather-icons',
  defaultIconPack:'feather'
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')

//experiment working change appname
Vue.prototype.$appName = 'Kings Swingsets'

